/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();
                var viewportHeight = $(document).height();
                var content = 'html,body';
                if(viewportWidth < 768){
                    content = '.page-wrap';
                }

                // Stop scrolling the page when user inteacts
                $('html,body').bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(){
                    $(content).stop();
                });

                // Smmoth scrolling script. Scrollable area depends on screensize.
                $('a[href*="#"]').not('[href^="#tab"]').not('[href="#"]').not('[href="#0"]').click(function () {
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            if(viewportWidth > 768 || target.selector === '#top') {
                                $(content).animate({
                                  scrollTop: target.offset().top - 100
                                }, 1000);
                                return false;
                            } else {
                                var main = $(".page-wrap:not(:animated)");
                                var destination = $(target).offset().top + main.scrollTop();
                                main.animate({ scrollTop: destination },500);
                                return false;
                            }
                        }
                    }
                });

                // Override smooth scroll script settings from beaverbuilder
                if ( 'undefined' !== typeof FLBuilderLayoutConfig ) {
                    FLBuilderLayoutConfig.anchorLinkAnimations.duration = 1000;
                    FLBuilderLayoutConfig.anchorLinkAnimations.easing = 'swing';
                    FLBuilderLayoutConfig.anchorLinkAnimations.offset = 100;
                }

                // Close old browser warning in IE lt 10 on click
                $('.close-oldie').click(function(){
                    $( ".oldie-warning" ).fadeOut();
                });

                // Toggles specific elements on specific scroll position
                $.fn.toggleElementsOnScroll = function() {
                    var viewportHeight = $(document).height();
                    var y = $(this).scrollTop();
                    if (y > viewportHeight/2) {
                        $('.to-top').removeClass('hidden');
                        $('.floaty-wrap').removeClass('hidden');
                    } else {
                        $('.to-top').addClass('hidden');
                        $('.floaty-wrap').addClass('hidden');
                    }
                };

                // MediaQuery triggered functions
                var mqDesktop = Modernizr.mq('(min-width: 768px)');
                if (mqDesktop) {

                    // Scroll-Top-Btn / Floaty-Bar fading function
                    // Different scroll targets on mobile / desktop
                    $(document).scroll(function() {
                        $(this).toggleElementsOnScroll();
                    });

                    // Define Vars for affix calcs
                    var nav = $('#header');
                    var navHeight = $(nav).height();
                    var offsetAffix = $('.header-content').offset();

                    // Makes the nav sticky
                    $(nav).affix({
                        offset: {
                            top: offsetAffix.top // You may have to add some extra px for borders etc.
                        }
                    });

                    // Add padding to body to prevent lil' page-jump when nav is stickfied
                    $(nav).on('affix.bs.affix', function () {
                        $('.page-wrap').css('margin-top', navHeight + 'px');
                    });

                    // Remove the x-tra padding after nav gets static again
                    $(nav).on('affixed-top.bs.affix', function () {
                        $('.page-wrap').css('margin-top', '0');
                    });

                    // Recalculate offset for sticky element and position for mega dropdown centering on resize
                    $( window ).resize(function() {
                        var offsetAffix = $(nav).offset();
                    });
                }
                else {
                    // Scroll-Top-Btn / Floaty-Bar fading function
                    // Different scroll targets on mobile / desktop
                    var lastScrollPos = 0;
                    var topbar = $('.topbar');
                    var topbarHeight = topbar.outerHeight();

                    $('.page-wrap').scroll(function() {
                        $(this).toggleElementsOnScroll();

                        var scrollPos = $(this).scrollTop();
                        if(scrollPos > lastScrollPos){
                            if(scrollPos > viewportHeight/14) {
                                topbar.addClass('slide-up');
                            }
                            else {
                                topbar.removeClass('slide-up');
                            }
                        } else {
                            topbar.removeClass('slide-up');
                        }
                        lastScrollPos = scrollPos;
                    });
                }

                // Initialize doubletaptogo on desktop-nav for dropdown touch support
                if($('html').hasClass('touchevents')) {
                    $( '.menu-main-navigation-container' ).doubleTapToGo();
                }

                // Start Load cart by Ajax
                if( 1 === jQuery( '#header .warenkorb' ).length ) {
    
                    var data = {
                        'action': 'load_woo_cart'
                    };
                    var updateCartCount = function() {
                        jQuery.post( woocommerce_params.ajax_url, data, function( response ) {
                            if( 1 === jQuery( '#header .cart-count' ).length ) {
                                jQuery( '#header .cart-count' ).text(response);
                            } else {
                                jQuery( '#header .warenkorb span' ).append( '<span class="cart-count">' + response + '</span>' );
                            }

                            if( 1 === jQuery( '#wc-offcanvas-nav .cart-count' ).length ) {
                                jQuery( '#wc-offcanvas-nav .cart-count' ).text(response);
                            } else {
                                jQuery( '#wc-offcanvas-nav .warenkorb span' ).append( '<span class="cart-count">' + response + '</span>' );
                            }

                            if( 1 === jQuery( '.mobile-cart .cart-count' ).length ) {
                                jQuery( '.mobile-cart .cart-count' ).text(response);
                            } else {
                                jQuery( '.mobile-cart a.cart' ).append( '<span class="cart-count">' + response + '</span>' );
                            }
                            
                        });
                    };

                    updateCartCount();

                    jQuery( 'body' ).on( 'added_to_cart removed_from_cart updated_cart_totals', function( e, fragments, cart_hash, this_button ) {
                        updateCartCount();
                    } );

                }
                // End Load cart by Ajax

            },
            finalize: function() {
                // Product table data-titles for yith wishlist responsive table
                var productRemove = yith_wl_table.yith_wl_th_product_remove;
                var productName = yith_wl_table.yith_wl_th_product_name;
                var productPrice = yith_wl_table.yith_wl_th_product_price;
                var productStock = yith_wl_table.yith_wl_th_product_stock;
                $('a.remove_from_wishlist').attr('aria-label', productRemove);
                $('td.product-name').attr('data-title', productName);
                $('td.product-price').attr('data-title', productPrice);
                $('td.product-stock-status').attr('data-title', productStock);
            }
        },
        // Home page
        'post_type_archive_product': {
            init: function() {
                // JavaScript to be fired on the shop page
                $('.product-categories-slider').slick({
                    dots: false,
                    arrows: true,
                    prevArrow: '<i class="fa custom-fa-angle-left arrow-prev" aria-hidden="true"></i>',
                    nextArrow: '<i class="fa custom-fa-angle-right arrow-next" aria-hidden="true"></i>',
                    infinite: false,
                    speed: 300,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 3,
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                          }
                        }
                        ]
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
